import axios from "axios";

const API_URL = 'https://ai-match.oxsico.com/api/chat-gpt/ai-score';

export const getAITextScore = async (text: string, language: string, isGitlab = false) => {

    let body: {text: string, language: string, tool?: string} = {
        text: text,
        language,
        tool: 'ai_detect'
    }

    if(isGitlab) {
        body = {...body};
    }


    let response = await axios.post(API_URL,body);
    return response.data;
}
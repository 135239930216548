// @ts-ignore
import { ReactComponent as OxsicoLogo } from 'assets/oxsico_logo.svg';
// @ts-ignore
import { ReactComponent as IdentificLogo } from 'assets/identific_logo.svg';
import './topBar.scss'
import {
    AppBar,
    Box, Button,
    CssBaseline,
    Divider, Drawer, IconButton, Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Toolbar,
    Typography
} from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import {IS_IDENTIFIC} from "settings";

interface IProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
let navItems = [
    {
        name: 'Registracija',
        link: 'https://app.oxsico.com/register'
    },
    {
        name: 'Apie Oxsico',
        link: 'https://lt.oxsico.com/apiemus/'
    },
    {
        name: 'Kontaktai',
        link: 'https://lt.oxsico.com/kontaktai/'
    }];

export const TopBar = (props: IProps) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    let Logo = OxsicoLogo;
    let logoLink = 'https://lt.oxsico.com/';


    if(IS_IDENTIFIC) {
        Logo = IdentificLogo;
        logoLink = 'https://identific.com/';
        navItems = [
            {
                name: 'Registracija',
                link: 'https://app.identific.com/register'
            },
            {
                name: 'Apie Identific',
                link: 'https://lt.identific.com/apie-mus'
            },
            {
                name: 'Kontaktai',
                link: 'https://lt.identific.com/kontaktai'
            }];
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box p={2} pt={3}>
                <a href={logoLink}><Logo style={{height: "40px"}} /></a>
            </Box>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav"
                    sx={{
                    background: 'white',
                    boxShadow: 'none',
                    color: "black",
                    px: {xs:4},
                    pt: {xs:0, sm:3},
                }} >
                <Toolbar disableGutters>
                    <Box sx={{display: { xs: 'none', sm: 'block' }}} ml={6}>
                        <a href={'https://lt.oxsico.com/'}><Logo /></a>
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >

                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block'} }}>
                        {navItems.map((item, index) => (
                            <Button color={'primary'} href={item.link} key={index}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}
import './App.css';
import {useEffect, useState} from "react";
import { BrowserRouter} from 'react-router-dom'
// import Lithuanian from "./pages/lithuanian";
import {Tooltip} from "react-tooltip";
import {SnackbarProvider} from "notistack";
import ReactGA from 'react-ga4';
import {Main} from "pages/main/main";
import {createTheme, ThemeProvider} from "@mui/material";
import {theme} from "theme";
import {IS_IDENTIFIC} from "settings";

function App() {
    const [train, setTrain] = useState(false);
    const hostname = window.location.hostname;

    useEffect(() => {
        ReactGA.initialize('G-L6J5SP346E');
        checkIdentific();
    }, [])

    const isGitLab = () => {
        return hostname === 'lingua-intellegens.gitlab.io';
    }

    function checkIdentific() {
        if(IS_IDENTIFIC) {
            document.title = 'Identific dirbtinio intelekto aptikimo įrankis';
            const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;
            if (link !== null) {
                link.href = '/favicon_identific.png';
            }
        }
    }

    return (
        <BrowserRouter basename={isGitLab() ? '/oxsico/ai-detect/' : ''}>
            <SnackbarProvider maxSnack={3}>
                <Tooltip id="tooltip"/>
                <ThemeProvider theme={theme}>
                <div className="App"><Main isGitlab={isGitLab()} /></div>
                </ThemeProvider>
            </SnackbarProvider>
        </BrowserRouter>
    );
}

export default App;

import axios from "axios";

const API_URL = 'https://login.oxsico.com/api/ai_result_rating';
export const setTextRating = async (text: string, rating: boolean) => {

    let body= {
        text, rating

    }

    let response = await axios.post(API_URL,body);
    return response.data;
}
import {TopBar} from "./TopBar/TopBar";
import './main.scss';
// @ts-ignore
import { ReactComponent as Team } from './Team.svg';
// @ts-ignore
import { ReactComponent as Spinner } from './Spinner.svg';
import {ChangeEvent, useEffect, useState} from "react";
import {getAITextScore} from "./getAITextScore";
import {useSnackbar} from "notistack";
import {joinWaitList} from "./submitEmail";
import {
    Box,
    Button,
    Checkbox,
    Grid, IconButton,
    InputAdornment,
    Link,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {
    ArrowForward,
    ThumbDownOutlined,
    ThumbUp,
} from "@mui/icons-material";
import {Footer} from "pages/main/Footer/Footer";
import {setTextRating} from "pages/main/setTextRating";
import {IS_IDENTIFIC} from "settings";

interface ISentenceData {
    score:number,
    text:string
};
interface IProps {
    isGitlab: boolean
}
export const Main = (props: IProps) => {
    const [loading, setLoading] = useState(false);
    const [waitListLoading, setWaitListLoading] = useState(false);
    const [sentences, setSentences] = useState([]);
    const [text, setText] = useState('');
    const [textLength, setTextLength] = useState(0);
    const [AIProbability, setAIProbability] = useState(0);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState('lt');
    const maxTextSize = 2000;
    const [allowedToRate, setAllowedToRate] = useState<boolean>(false);
    const supportedLanguages = [
        {
            value: 'lt',
            name: "Lietuvių",
        },
        {
            value: 'es',
            name: "Espaniol",
        },
        {
            value: 'it',
            name: "Italiano",
        },
        {
            value: 'en',
            name: "English",
        },
        {
            value: 'fr',
            name: "Français",
        },
        {
            value: 'de',
            name: "Deutsch",
        },
        {
            value: 'multi',
            name: "Multi",
        }
    ]

    const { enqueueSnackbar } = useSnackbar();

    const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
        setTextLength(event.target.value.length);
    }

    const handleEmailInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleTextSubmit = () => {
        setLoading(true);
        const submittableText= getSubmittableText();
        getAITextScore(submittableText, language, props.isGitlab).then(
            (data: any) => {
                setAllowedToRate(true);
                setSentences(data);
            }
        ).catch(() => {
            enqueueSnackbar('Dienos limitas pasiektas.', {variant: 'error',});
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleJoinWaitList = () => {
        setWaitListLoading(true);
        joinWaitList(email).then(
            () => {
                enqueueSnackbar('El. paštas pridėtas į sąrašą', {variant: 'success',});
            }
        ).catch(() => {
            enqueueSnackbar('Įvyko klaida', {variant: 'error'});
        }).finally(() => {
            setWaitListLoading(false);
        })
    }

    const getSubmittableText = () => {
        let submittableText = text.substring(0, maxTextSize);
        if(props.isGitlab) {
            submittableText = text;
        }
        return submittableText;
    }

    const handleRateSubmit = (rating: boolean) => {
        setAllowedToRate(false);

        const submittableText = getSubmittableText();
        setTextRating(submittableText, rating).then(
            () => {
                enqueueSnackbar('Įvertinimas užskaitytas', {variant: 'success',});
            }
        ).catch(() => {
            enqueueSnackbar('Įvertinimo užskaitymas nepavyko', {variant: 'error',});
            setAllowedToRate(true);
        }).finally(() => {

        })
    }

    const calcAIProbability = () => {
        let count = 0;
        let totalScore = 0;
        sentences.forEach( (sentenceData: ISentenceData) => {
            totalScore += sentenceData.score;
            count++;
        })
        const probability = roundNumber((totalScore/count)*100);
        if(probability>0) {
            setAIProbability(probability);
        }
    }

    const roundNumber = (number: number) => {
        return Math.round(number * 100) / 100
    }

    useEffect(()=> {
        calcAIProbability();
    }, [sentences]);

    const handleLanguageSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        setLanguage(event.target.value);
    };

    return (
        <>
            <TopBar/>
            <Box sx={{width: "100%"}}>
                <Box sx={{backgroundColor: "white", pt: {xs: 11, sm: 18}, pb: {xs: 2, sm: 5}, px: {xs:3, sm:8} }}>
                    <Typography sx={{
                        fontSize: {xs: "22px", sm:"39px"},
                        fontWeight: 700,
                        letterSpacing: 0,
                        textAlign: "left",
                    }}
                                mb={5}>
                        DI kurto teksto atpažinimo sistema [Beta]
                    </Typography>

                    <TextareaAutosize style={
                        {
                            fontFamily: "inherit",
                            borderRadius: 10,
                            width: "100%",
                            padding: 20
                        }
                    }
                      minRows={8}
                      value={text}
                      onChange={(e) => handleInput(e)}
                    />
                    <Grid container spacing={2} className={"submit-area"} sx={{mt: {xs: 0, sm:3} }}>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <label>
                                Kalba:
                                <select value={language} onChange={handleLanguageSelect}>
                                    {supportedLanguages.map((language) => (
                                        <option value={language.value}>{language.name}</option>
                                    ))}
                                </select>
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{display: {xs: "block", sm:"none"}}} textAlign={'right'}>
                            <Typography variant={'caption'} style={{color: textLength>maxTextSize? "red": "#556CED"}}>
                                {textLength}/{maxTextSize} ženklų
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}
                              sx={{paddingTop: "6px !important", textAlign: {xs: "center", sm:"left"}}}>
                            <Checkbox
                                   checked={acceptTerms}
                                   onChange={() => {setAcceptTerms(!acceptTerms)}}
                            />
                            <Link
                                variant="body2"
                                href={'https://www.oxsico.com/privacy-policy/'}
                                underline="none"
                                color={"primary"}
                            >
                                sutinku su naudojimo taisyklėmis.
                            </Link>
                        </Grid>
                        <Grid item xs={4} sm={2} sx={{display: {xs: "none", sm:"block"}}} textAlign={'right'}>
                            <Typography variant={'caption'} color={(textLength>maxTextSize)? 'error': "primary"}>
                                {textLength}/{maxTextSize} ženklų
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{paddingTop: "6px !important"}}>
                            <Button
                                variant="contained"
                                color={'primary'}
                                onClick={() => {handleTextSubmit()}}
                                disabled={loading || textLength === 0 || !acceptTerms}
                            >
                                {loading ? <Spinner /> : "TIKRINTI"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{
                    p: {xs:3, sm:8},
                    }}>
                    <Grid container spacing={2} mb={4}>
                        <Grid item xs={12} sm={8} sx={{mb:{xs: 2, sm:0 }}}>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    letterSpacing: 0,
                                    textAlign: "left",
                                }}
                                color={IS_IDENTIFIC ? 'white':'inherit'}
                                variant={"h5"}
                            >
                                Tikimybė, kad tekstas parašytas DI {AIProbability}%
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container   alignItems="center"
                                  justifyContent="center"
                                  sx={{backgroundColor: "white", borderRadius: 2, padding:1}}
                                  // spacing={2}
                            >

                                <Grid item mr={2}>
                                    <Typography fontWeight={"bold"} color={IS_IDENTIFIC? '': "#541978"}>Įvertink rezultatą</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton aria-label={'nykštys viršun'} sx={{mr: 1}} disabled={!allowedToRate} onClick={() => handleRateSubmit(true)}
                                    color={"secondary"}>
                                        <ThumbUp sx={{
                                            color: allowedToRate ? (IS_IDENTIFIC? 'black':"#B355ED") : 'inherit'
                                        }}/>
                                    </IconButton>
                                    <IconButton aria-label={'nykštys žemyn'} disabled={!allowedToRate} onClick={() => handleRateSubmit(false)}>
                                        <ThumbDownOutlined sx={{
                                            color: allowedToRate ? (IS_IDENTIFIC? 'black':"#B355ED") : 'inherit'
                                        }}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Box className={'text-container'} sx={{
                        border: "1px solid #A7CAF0",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        minHeight: "80px"
                    }}
                         p={3}
                         mb={6}
                    >
                        {sentences.map((sentenceData: ISentenceData, index) => {
                            const score = roundNumber(sentenceData.score);
                            const scorePercentage = roundNumber(score * 100);
                            return <span data-tooltip-id={"tooltip"} data-tooltip-content={`DI tikimybė: ${scorePercentage}%`} className="di-text" data-ai={scorePercentage} key={index}>{sentenceData.text}</span>
                        })}
                    </Box>

                    <Grid container spacing={3} mb={4}>
                        <Grid item sm={2}>
                            <Team style={{width: "100%"}}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                variant={"body2"}
                                textAlign={"left"}
                                color={IS_IDENTIFIC ? 'white':'inherit'}
                            >
                                Violetinė spalva nurodo tikimybę, jog <span className={'di-text'} data-ai={100}>tekstas buvo sukurtas DI</span>.
                                Kuo spalva intensyvesnė tuo tikimybė, kad sakinys yra sugeneruotas DI yra didesnė. <span className={'di-text'}  data-ai={55}>Mažėjant spalvos intensyvumui</span> tikimybė, kad tekstas parašytas DI mažėja.
                                <br/>
                                <br/>
                                Vertinant gautus rezultatus, svarbu atminti paprastą taisyklę. Jeigu modelis nurodo didesnę  <span className={'di-text'}  data-ai={55}>nei 50 proc.</span> tikimybę, jog tekstas buvo sukurtas DI – galime manyti, kad tekstą parašė DI. Ir priešingai, jei pateikiama tikimybė siekia mažiau nei 49 proc., rekomenduotina tokį turinį vertinti kaip sukurtą žmogaus.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography mb={3} variant={"h5"}
                                        fontWeight={"bold"}
                                        color={IS_IDENTIFIC ? 'white':'primary'}
                            >Prenumeruoti naujienas</Typography>
                            <TextField
                                   placeholder={'Jūsų el. paštas'}
                                   onChange={(e) => handleEmailInput(e)}
                                   value={email}
                                   size={"small"}
                                   style={{backgroundColor: "white"}}
                                   InputProps={{
                                       endAdornment:
                                           <InputAdornment sx={{cursor: "pointer"}} position="end">
                                               <ArrowForward onClick={() => handleJoinWaitList()} />
                                           </InputAdornment>
                                   }}
                            />

                        </Grid>
                    </Grid>
                </Box>

            </Box>


            <Footer/>
        </>
    )
}
import {Box, Link} from "@mui/material";
import {IS_IDENTIFIC} from "settings";

export const Footer = () => {
    return <Box p={3}>
        <Link mr={2} underline="none" fontSize={10} color={"primary"} display={"inline-block"}>© 2023 {IS_IDENTIFIC ? 'IDENTIFIC' : 'OXSICO'}</Link>
        <Link mr={2} underline="none" fontSize={10} color={"inherit"} display={"inline-block"} href={'https://www.oxsico.com/oxsico-cookie-policy/'}>Cookie Policy</Link>
        <Link mr={2} underline="none" fontSize={10} color={"inherit"} display={"inline-block"} href={'https://www.oxsico.com/privacy-policy/'}>Privacy Policy</Link>
        <Link mr={2} underline="none" fontSize={10} color={"inherit"} display={"inline-block"} href={'https://www.oxsico.com/terms-of-use/'}>Terms of use</Link>
        <Link underline="none" fontSize={10} color={"inherit"} display={"inline-block"} href={'https://lt.oxsico.com/dirbtinio-intelekto-naudojimo-mokymosi-ir-tyrimu-tikslais-gaires/'}>
            Dirbtinio intelekto naudojimo mokymo(si) ir tyrimų tikslais gairės
        </Link>
    </Box>
}
import { createTheme } from '@mui/material/styles';
import {IS_IDENTIFIC} from "settings";

const IdentificTheme = createTheme({
    palette: {
        primary: {
            main: '#00402D',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'Montserrat',
            'sans-serif'
        ].join(','),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    lineHeight: '20px',
                    fontWeight: 600,
                    padding: '10px 20px',
                    borderRadius: 21,
                    boxShadow: 'none',
                    ':hover': {
                        boxShadow: 'none',

                    },
                },
                containedPrimary: {
                    backgroundColor: "#afe000",
                    color: '#000',
                    ':hover': {
                        backgroundColor: '#00402D',
                        color: '#fff'
                    },
                }
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                'textarea': {

                },
                'body': {
                    backgroundColor: '#058157 !important',
                },
                'h5': {
                    color: '#fff'
                },
            },
        },
    }
});

const OxsicoTheme = createTheme({
    palette: {
        primary: {
            main: '#556CED',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'Montserrat',
            'sans-serif'
        ].join(','),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    lineHeight: '20px',
                    fontWeight: 600,
                    padding: '10px 20px',
                    borderRadius: 21,
                    boxShadow: 'none',
                    ':hover': {
                        boxShadow: 'none',

                    },
                },
                containedPrimary: {
                    backgroundColor: "#556CED",
                    ':hover': {backgroundColor: '#657af0'},
                }
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                'textarea': {
                    // Your styles here
                    borderColor: '#556CED'
                },
                'body': {
                    backgroundColor: '#F2F8FF !important',
                },
            },
        },
    }
});

export const theme = IS_IDENTIFIC ? IdentificTheme : OxsicoTheme;
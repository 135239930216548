import axios from "axios";

const API_URL = 'https://login.oxsico.com/api/join_ai_waitlist';
export const joinWaitList = async (email: string) => {

    const body = {
        email
    }

    let response = await axios.post(API_URL,body);
    return response.data;
}